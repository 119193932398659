// function updateCartItemCount() {
//     const sidecartCountElement = document.querySelector('.xoo-wsc-items-count');
//     if (!sidecartCountElement) {
//         console.error('Side cart count element not found');
//         return;
//     }

//     let sidecartCount = sidecartCountElement.textContent.trim();
//     sidecartCount = parseInt(sidecartCount.replace(/\D/g, ''));

//     const navCountElements = document.querySelectorAll('.btn_panier .cart-contents-count');
//     if (navCountElements.length > 1) {
//         for (let i = 1; i < navCountElements.length; i++) {
//             navCountElements[i].remove();
//         }
//     }

//     const navCountElement = navCountElements[0];
//     if (!navCountElement) {
//         console.error('Nav count element not found');
//         return;
//     }

//     let navCount = parseInt(navCountElement.textContent);

//     if (sidecartCount !== navCount) {
//         navCountElement.textContent = sidecartCount;
//     }
// }

// // Observe the side cart for changes
// const sideCartElement = document.querySelector('.xoo-wsc-modal');
// if (sideCartElement) {
//     const observer = new MutationObserver((mutations) => {
//         mutations.forEach((mutation) => {
//             if (mutation.type === 'childList' || mutation.type === 'subtree') {
//                 updateCartItemCount();
//             }
//         });
//     });

//     observer.observe(sideCartElement, { childList: true, subtree: true });
// } else {
//     console.error('Side cart element not found');
// }